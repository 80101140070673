import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import dots from '../../assets/images/icons/dotted-line.svg';
import { flexCenter, flexStart, px } from './layout/styles/classes';

const StyledResume = styled.section`
    ${px};
    padding-top: 40px;
    padding-bottom: 70px;
    position: relative;

    ::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: -46px;
        height: 16px;
        right: 0;
        background: url(${dots}) repeat-x;
    }

    @media (min-width: 1024px) {
        ${flexCenter};
        align-items: flex-start;
        padding-top: 80px;
        padding-bottom: 0;

        ::after {
            right: unset;
            width: 430px;
            left: 0;
            bottom: 0;
        }

        > div:last-of-type {
            max-width: 635px;
        }
    }

    @media (min-width: 1366px) {
        ::after {
            width: 592px;
        }
    }
`;

const DrImg = styled.div`
    margin-bottom: 40px;

    > .gatsby-image-wrapper {
        max-width: ${({ theme }) => theme.sizes.md};
        border-radius: 15px;
        aspect-ratio: 343 / 392;
        opacity: 0.9999;
    }

    > p {
        font-weight: 600;
        margin: 16px 0 0;
    }

    @media (min-width: 1024px) {
        margin-right: 3.819vw; //55px at 1440p

        > .gatsby-image-wrapper {
            aspect-ratio: 374 / 486;
            max-width: 374px;
        }
    }
`;

const Details = styled.div`
    ${flexStart};

    > p {
        display: flex;
        flex-direction: column;
        align-self: stretch;
        margin: 0 28px 0 0;

        > span {
            ${flexCenter};
            width: 56px;
            height: 56px;
            color: ${({ theme }) => theme.colors.purple1};
            border-radius: 50%;
            border: 5px solid ${({ theme }) => theme.colors.purple1};
            font-size: 1.5rem;
            line-height: 175.18%;
            font-weight: 600;
            flex-shrink: 0;
        }

        ::after {
            content: '';
            position: relative;
            width: 0;
            height: 100%;
            border-right: 2px dashed ${({ theme }) => theme.colors.purple3};
            left: calc(50% - 1px);
            top: 0;
        }
    }

    > div {
        > h4 {
            color: ${({ theme }) => theme.colors.orange1};
            margin: 0;
        }
    }

    :last-of-type {
        > p {
            border-bottom: 3.71px solid ${({ theme }) => theme.colors.purple1};
        }

        ul {
            margin-bottom: 0;
        }
    }

    @media (min-width: 1024px) {
        ul {
            margin: 32px 0;
        }

        :last-of-type {
            ul {
                margin-bottom: 0;
            }
        }
    }
`;

export const DoctorResume = () => {
    return (
        <StyledResume>
            <DrImg>
                <StaticImage
                    src="../../assets/images/dr-nuttall.jpg"
                    alt="doctor nuttall smiles"
                    width={520}
                    loading="eager"
                />
                <p>Dr. Kent Nuttall, DMD</p>
            </DrImg>
            <div>
                <Details>
                    <p>
                        <span>1</span>
                    </p>
                    <div>
                        <h4>EDUCATION</h4>
                        <ul>
                            <li>Brigham Young University – BYU – Utah</li>
                            <li>BYU – Zoology Biochemistry – 1985-1991</li>
                            <li>OHSU – Oregon Health Sciences University – 1991-1995</li>
                            <li>Ladera Ranch Implant Institute</li>
                        </ul>
                    </div>
                </Details>
                <Details>
                    <p>
                        <span>2</span>
                    </p>
                    <div>
                        <h4>CONTINUING EDUCATION</h4>
                        <ul>
                            <li>Graduate of Advanced Implant STEPS Program</li>
                            <li>Chen Laser Institute</li>
                            <li>New Tom 3D Cone Beam Imaging</li>
                            <li>Advanced Botox – Frontline TMJ – Facial Pain</li>
                            <li>O.C.S. Sedation – Credentialed</li>
                        </ul>
                    </div>
                </Details>
                <Details>
                    <p>
                        <span>3</span>
                    </p>
                    <div>
                        <h4>PROFESSIONAL MEMBERSHIPS AND TITLES HELD</h4>
                        <ul>
                            <li>American Academy of Cosmetic Dentistry – AACD</li>
                            <li>American Academy of Facial Esthetics – AAFE</li>
                            <li>DOCS Sedation</li>
                            <li>King Study Club</li>
                            <li>American Dental Society – ADA</li>
                            <li>Washington State Dental Association – WSDS</li>
                            <li>Seattle King County Dental Society – SKCDS</li>
                        </ul>
                    </div>
                </Details>
            </div>
        </StyledResume>
    );
};
