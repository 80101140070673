import { Article } from '@/Article';
import { PlainBtn } from '@/Buttons/PlainBtn';
import { CommonHero } from '@/CommonHero';
import { DoctorResume } from '@/DoctorResume';
import { Seo } from '@/layout/Seo';
import { flexCenter } from '@/layout/styles/classes';
import Slider from '@/Slider';
import { Video } from '@/Video';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment } from 'react';
import { Navigation } from 'swiper';
// eslint-disable-next-line
import 'swiper/css/navigation';
// eslint-disable-next-line
import { SwiperSlide } from 'swiper/react';

const Videos = styled.section`
    padding-bottom: 48px;

    > h3 {
        margin: 0 0 24px;
        text-align: center;
    }

    .swiper-slide {
        width: max-content;

        :first-of-type {
            margin-left: 16px;
        }

        :last-of-type {
            margin-right: 16px;
        }
    }

    @media (min-width: 768px) {
        .swiper-slide {
            :first-of-type {
                margin-left: 40px;
            }

            :last-of-type {
                margin-right: 40px;
            }
        }
    }

    @media (min-width: 1024px) {
        padding-bottom: 64px;

        > h3 {
            margin-bottom: 40px;
        }
    }

    @media (min-width: 1150px) {
        .swiper-slide {
            :first-of-type {
                margin-left: 7vw;
            }
        }
    }

    @media (min-width: 1366px) {
        padding-bottom: 128px;

        .swiper-slide {
            :first-of-type {
                margin-left: 11vw;
            }

            :last-of-type {
                margin-right: 64px;
            }
        }
    }

    @media (min-width: 1440px) {
        .swiper-slide {
            :first-of-type {
                margin-left: 13vw;
            }

            :last-of-type {
                margin-right: 80px;
            }
        }
    }

    @media (min-width: 1600px) {
        .swiper-slide {
            :first-of-type {
                margin-left: 16vw;
            }
        }
    }

    @media (min-width: 1800px) {
        .swiper-slide {
            :first-of-type {
                margin-left: 21vw;
            }
        }
    }
`;

const videoStyles = css`
    &,
    > * {
        height: min(50vw, 290px);
        aspect-ratio: 300 / 167;
        width: 520px;
        max-width: 85vw;
    }

    @media (min-width: 1024px) {
        &,
        > * {
            height: min(50vw, 392px);
            aspect-ratio: 705 / 394;
            width: 700px;
        }
    }

    @supports (aspect-ratio: 1/1) {
        &,
        & > * {
            height: unset;
        }
    }
`;

const Btns = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    margin-right: 16px;

    @media (min-width: 768px) {
        margin-right: 40px;
    }

    @media (min-width: 1366px) {
        margin-right: 64px;
    }

    @media (min-width: 1440px) {
        margin-right: 80px;
    }
`;

const Btn = styled(PlainBtn)<{ prev?: string }>`
    ${flexCenter};
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.colors.purple1};
    width: 36px;
    height: 36px;
    transition: opacity 0.3s ease-in-out;
    margin-right: ${({ prev }) => (prev ? '24px' : 0)};

    ::before {
        content: '';
        height: 10px;
        width: 10px;
        border-right: 2px solid ${({ theme }) => theme.colors.purple1};
        border-bottom: 2px solid ${({ theme }) => theme.colors.purple1};
        transform: ${({ prev }) => (prev ? ' rotate(315deg) scale(-1)' : 'rotate(315deg) ')};
        margin-left: ${({ prev }) => (prev ? '4px' : '-4px')};
    }

    &.dr-kent-slider-disabled {
        pointer-events: none;
        opacity: 0.3;

        ::before {
            animation: none !important;
        }
    }

    &.swiper-button-lock {
        display: none;
    }

    :hover,
    :focus {
        ::before {
            animation: ${({ prev }) =>
                prev
                    ? 'translatePrevArrow 0.7s ease-out infinite'
                    : 'translateArrow 0.7s ease-out infinite'};
        }
    }

    @keyframes translateArrow {
        33% {
            transform: rotate(315deg) translate(-2px, -2px);
        }

        66% {
            transform: rotate(315deg) translate(2px, 2px);
        }

        100% {
            transform: rotate(315deg) translate(0, 0);
        }
    }

    @keyframes translatePrevArrow {
        33% {
            transform: rotate(315deg) translate(2px, 2px) scale(-1);
        }

        66% {
            transform: rotate(315deg) translate(-2px, -2px) scale(-1);
        }

        100% {
            transform: rotate(315deg) translate(0, 0) scale(-1);
        }
    }
`;

const MeetDrKent: Page = () => {
    const sliderSettings = {
        spaceBetween: 16,
        slidesPerView: 'auto' as const,
        breakpoints: {
            1024: {
                spaceBetween: 24,
            },
        },
        navigation: {
            nextEl: '.dr-kent-slider-next',
            prevEl: '.dr-kent-slider-prev',
            disabledClass: 'dr-kent-slider-disabled',
        },
        modules: [Navigation],
    };
    return (
        <Fragment>
            <Seo
                title="Meet Dr. Kent Nuttal | Preventive Dentistry Services"
                description="Meet Dr. Kent Nuttall, the best dentist in Auburn and surrounding areas. Experience professional dental care at Nuttall Smiles. Connect with us!"
            />
            <CommonHero />
            <DoctorResume />

            <Article>
                <p>
                    My friends and patients always ask me why I became a dentist. Let me tell you my
                    story. I grew up as the oldest of four children. The atmosphere in our household
                    was one of love and acceptance. My parents worked hard to create a positive
                    environment where I felt free to learn about myself as I progressed through
                    life. Emphasis was always placed on my accomplishments instead of my mistakes,
                    and as a result my self-esteem and confidence were firmly established. Growing
                    up in Yakima, Washington, I was surrounded by fields of peaches, apricots, and
                    cherries. It was in the orchards that, at a young age, I learned and applied the
                    work ethic that has gotten me to where I am today. In fact, when I think of my
                    childhood, I can still smell the fresh fruit in my mind.
                </p>
                <p>
                    At the age of nineteen I chose to serve a two-year mission for the Church of
                    Jesus Christ of Latter-day Saints. I lived and served in São Paulo, Brazil, the
                    third largest city in the world. While in Brazil I learned a lot of “life
                    lessons,” including why it is important to understand other people first, rather
                    than seeking to have myself understood. To a young man from America, it seemed
                    as if everyone in Brazil had bad teeth. It was during this period that I learned
                    that dental health had more of an impact on day-to-day life than I had ever
                    imagined. These experiences culminated while observing a father extract his
                    young child’s abscessed tooth on the couch at their home. It was then that I
                    knew I could make a difference in the lives of others. I could not only become
                    their dentist, but also be their friend. Now that I am known as the “numb and
                    number” dentist, I look back on that day and am thankful that I chose dentistry.
                </p>
                <p>
                    Fast forward twenty-three years and seven kids later, you find me here in
                    Auburn, Washington, with a good portion of my life still ahead of me. Hopefully
                    I have learned enough from my mistakes to make life easier from here on out. The
                    reason I tell you this story is so that you can understand our practice vision
                    and where it came from. Here at Nuttall Smiles, we accept people for who they
                    are because it is part of the process of helping them to feel good about
                    themselves. As our patient’s self-esteem is raised, magical things start to
                    happen! First, the patients make better decisions in their lives, and second, we
                    gain not only a patient, but a friend. As health care providers, our lives start
                    to have the purpose that we have been striving for. Just like my parents loved
                    and accepted me as a child, our patients feel validated and accepted as human
                    beings as we connect with them. The following statement can summarize this
                    process, “A good office team can feel good about themselves, while a great
                    office helps their patients feel better about themselves and their lives.” You
                    will feel like you are being worked with instead of being worked on. As a
                    result, this is a place where both patients and staff look forward to coming
                    every day! Thank you for taking the time to read this! We look forward to seeing
                    you in the office!
                </p>
            </Article>
            <Videos>
                <h3>Videos</h3>

                <Slider sliderSettings={sliderSettings}>
                    <SwiperSlide>
                        <Video videoId="1OG8jjV5dvo" css={videoStyles}>
                            <StaticImage
                                src="../../../assets/images/about/meet-dr-kent/01.jpg"
                                alt="free dentistry day for underprivileged"
                                width={700}
                            />
                        </Video>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Video videoId="5IkL8D1L0hA" css={videoStyles}>
                            <StaticImage
                                src="../../../assets/images/about/meet-dr-kent/02.jpg"
                                alt="doctor nuttall smiles on nuttall smiles being one stop shop"
                                width={700}
                            />
                        </Video>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Video videoId="7Y8GCPaYXss" css={videoStyles}>
                            <StaticImage
                                src="../../../assets/images/about/meet-dr-kent/03.jpg"
                                alt="doctor nuttall smiles on scientific evidence"
                                width={700}
                            />
                        </Video>
                    </SwiperSlide>
                </Slider>

                <Btns>
                    <Btn prev="true" aria-label="previous slide" className="dr-kent-slider-prev" />
                    <Btn aria-label="next slide" className="dr-kent-slider-next" />
                </Btns>
            </Videos>
        </Fragment>
    );
};

export default MeetDrKent;
